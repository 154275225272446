import axios from "axios";
import { DailySetting } from '@/types/daily_setting'
export type GetDateParams = {
  start_date: string,
  end_date: string
}

export class DailySettingRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = DailySettingRepository.DOMAIN + "v1/projects/" + this.projectId + "/daily_settings/";
  }


  async get(): Promise<DailySetting> {
    return axios.get(this.url, {}).then((response) => {
      return response.data;
    });
  }
  async save(params: DailySetting): Promise<DailySetting> {
    return axios.post(this.url, params).then((response) => {
      return response.data;
    });
  }
}
