import { ref, computed } from "vue";
import { useRoute } from 'vue-router'
import { DailySetting } from "@/types/daily_setting"
import { ElMessage } from "element-plus"
import { DailySettingRepository } from '@/repositories/DailySettingRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const DailySettingLogic = () => {
    const route = useRoute();
    const projectId = computed(() => Number(route.params.project_id))

    const dailySettingLoading = ref(false)
    const daily_setting = ref<DailySetting>()
    const get = async () => {
        dailySettingLoading.value = true;
        const repository: DailySettingRepository = new DailySettingRepository(projectId.value);
        try {
            daily_setting.value = await repository.get();
            dailySettingLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    const save = async (): Promise<boolean> => {
        // 表示されたものと違う場合は何もしない
        if (daily_setting.value == undefined) {
            return false
        }
        dailySettingLoading.value = true;
        const repository: DailySettingRepository = new DailySettingRepository(projectId.value);
        try {
            daily_setting.value = await repository.save(daily_setting.value);
            dailySettingLoading.value = false;
            ElMessage({
                showClose: true,
                message: "保存しました",
                type: 'success'
            });
            return true;

        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
            return false;
        }

    };
    const dailySettingStateReturn = () => {
        return {
            get,
            save,
            daily_setting,
            dailySettingLoading
        }
    }
    return {
        dailySettingStateReturn,
        ...dailySettingStateReturn()
    }
}



